import Hero from "./HeroComponents/Hero";
import "./App.css"
import AppLayout from './AppLayout';
import Navbar from "./NavigationComponents/Navbar";
import About from "./MainComponents/About";
import Gallery from "./GalleryComponents/Gallery.js";
import Sponsors from "./SponsorsComponents/SponsorView.js";
import Footer from "./FooterComponents/Footer"
import Iterations from "./MainComponents/Past-Iterations";
import FAQView from "./FAQComponents/FAQView";
import Press from "./PressComponents/Press.js"
import PastWinners from "./MainComponents/Past-Winners.js";

function App() {
  return (
    <AppLayout>
      <Navbar />
      <Hero />
      <div className="body-section"><About /></div>
      <div className="body-section"><Iterations/></div>
      <div className="body-section"><PastWinners/></div>
      <div className="body-section"><Gallery /></div>
      <div className="body-section"><Sponsors /></div>
      <div className="body-section"><Press /></div>
      <div className="body-section"><FAQView /></div>
      <Footer />
    </AppLayout>
  );
}

export default App;
