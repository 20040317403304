import React from 'react'
import "./Hero.css"
import "./Button.css"
import arrowImg from './arrow.svg';
import GlowButton from '../GlowButton/GlowButton';
import { Link } from 'react-scroll';
import { Fade } from 'react-awesome-reveal';

export default function Hero() {
  return (
    <Fade bottom duration={2000} distance="40px">
    <header id='hero-page'>
      <div className='top-title-container'>
        <span className='top-title glowOutside'>Hack</span>
        <span className='top-title nyu-purple glowOutside'>NYU</span>
      </div>
      <h1 >A Global Hackathon Hosted by NYU</h1>
      <p className='subtitle'>
        We are back with a new theme, fun tracks, and more prizes for the 2024-25 Hacking Season!
      </p>
      <center>
      <div className='hero-buttons-container'>
        <GlowButton value='HackNYU 2025' onClick={() => window.open('https://hacknyu.org/2025')}/>
      </div>
      </center>
      <Link to="about-page" spy={true} smooth={true} offset={-100} duration={500}>
        <img src={arrowImg} className="arrow heroArrow" alt="arrow down" />
      </Link>
    </header> 
    </Fade>
  )
}
