import React from 'react'  
import GlowButton from "../GlowButton/GlowButton.js";
import { Fade } from 'react-awesome-reveal';

export default function SponsorForm() {
  return (
    <div className="column">
      <Fade direction='left' duration={2000}>
        <div>
            <h2 className="title">Interested in Sponsoring Us?</h2>
            <p className="sponsor-description">Please send us your FULL NAME, EMAIL, and COMPANY, and we will contact you shortly. </p>
            <GlowButton className="sponsor-button" onClick={() => window.open("mailto:sponsorship.hack@nyu.edu")} value="Click to Sponsor Us!" />
        </div>
        </Fade>
    </div>
  )
}
